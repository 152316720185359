@import "../../styles/main.scss";

#header-navigation {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  .info-links {
    display: flex;
    gap: 20px;
    a {
      font-size: 16px;
      color: $color-prussian-blue;
      font-family: "Roboto-Medium";
      &:hover {
        color: $color-rouge;
      }
    }
  }

  .menu-icon {
    display: flex;
    gap: 20px;

    margin: 0 20px 5px;

    .nav-icon {
      width: 23px;

      filter: brightness(0) saturate(100%) invert(82%) sepia(1%) saturate(876%) hue-rotate(286deg) brightness(90%)
        contrast(84%);
      &:hover {
        filter: brightness(0) saturate(100%) invert(19%) sepia(12%) saturate(6756%) hue-rotate(186deg) brightness(93%)
          contrast(93%);
      }
    }

    svg {
      path {
        fill: $color-lily;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .info-links {
      flex-direction: column;
      gap: unset;

      a {
        font-size: 12px;
      }
    }
    .menu-icon {
      gap: 10px;
      margin: 0 10px 5px;

      .nav-icon {
        width: 18px;
      }
    }
  }
}
