@import "../../styles/main.scss";

#action_buttons {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;

  .action-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: $color-prussian-blue;
    font-family: "Roboto-Medium";
    img {
      width: 20px;
      height: 20px;
    }
  }

  .start-writing-buttons {
    display: flex;
    gap: 30px;
    justify-content: space-around;
    margin: 20px 0;
    button {
      min-width: 140px;
    }
  }
}
