@import "../../../styles/main.scss";

#articles_page {
  margin: 40px auto;
  max-width: 820px;

  .articles-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 20px 0 30px;
    }

    .header-divider {
      width: 5px;
      height: 80px;
      background-color: $color-prussian-blue; // for browsers that do not support gradient
      background-image: linear-gradient($color-prussian-blue, $color-rouge);
    }

    .header-text {
      font-family: "Roboto-Medium";
      font-size: 20px;
      color: $color-lily;
    }
  }

  .topics-list {
    margin: 40px 0;

    .topic {
      margin-bottom: 30px;
      padding: 10px;
      &-title {
        font-size: 20px;
        color: $color-prussian-blue;
        font-family: "Roboto-Bold";
        margin-bottom: 10px;
      }

      &-description {
        font-size: 16px;
      }

      &:hover {
        box-shadow: 0 3px 5px 0 rgba(18, 60, 105, 0.17);
        border-radius: 10px;
        transform: scale(1.05);
        transition: transform 0.5s ease-out;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #articles_page {
    margin: 20px auto;

    .articles-header {
      margin-bottom: 20px;
      flex-direction: column;

      h1 {
        margin: 0;
      }

      .header-divider {
        width: 100%;
        height: 3px;
        background-color: $color-prussian-blue; // for browsers that do not support gradient
        background-image: linear-gradient(to right, $color-prussian-blue, $color-rouge);
      }

      .header-text {
        font-size: 18px;
      }
    }

    .topics-list {
      margin: 20px 0 40px 0;
    }
  }
}
