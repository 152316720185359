#go_to_result_page_modal {
  &.basic-modal {
    min-height: 150px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-around;
    .general-button {
      min-width: 100px;
    }
  }
}
