@import "../../styles/main.scss";

#start_writing_modal {
  font-size: 14px;

  .modal-header {
    font-size: 20px;
    font-family: "Roboto-Bold";
    color: $color-prussian-blue;
  }
  .modal-content {
    .more-info {
      font-size: 12px;
      margin-top: 20px;
      color: $color-lily;
      .info-link {
        color: $color-prussian-blue;
        font-family: "Roboto-Medium";
        margin-left: 5px;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-around;
    .general-button {
      width: 100px;
    }
  }
}
