@import "../../styles/main.scss";

#page_404 {
  margin: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  h1 {
    font-size: 72px;
  }

  h2 {
    font-size: 32px;
    color: $color-rouge;
    font-family: "Roboto-Medium";
    text-align: center;
  }

  .general-button {
    min-width: 200px;
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 20px;
    }
  }
}
