@import "../../styles/main.scss";

#home_page {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;

  .main-content {
    width: 100%;
    padding: 20px 0;

    .main-header {
      margin: 20px 0 30px;
    }

    .main-text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;

      > div {
        display: flex;
        justify-content: center;
        gap: 10px;

        p {
          font-size: 16px;
          display: flex;
        }

        .bullet-point {
          width: 30px;
          height: 30px;
        }
      }
    }

    .start-writing-buttons {
      display: flex;
      gap: 30px;
      justify-content: space-around;
      margin: 20px 0;
    }

    .secondary-header {
      font-size: 32px;
      color: $color-rouge;
      font-family: "Roboto-Medium";
      text-align: center;
      margin: 25px 0;
    }

    .secondary-text {
      font-size: 16px;

      .highlight {
        background-color: $color-prussian-blue;
        color: $color-white;
        padding: 2px 5px;
      }

      .learn-more-link {
        margin-left: 10px;
        color: $color-prussian-blue;
        font-family: "Roboto-Medium";
        background-color: $color-fair-pink;
        padding: 2px 5px;
        border-radius: 5px;

        &:hover {
          color: $color-white;
          background-color: $color-prussian-blue;
        }
      }
    }

    .article-link {
      margin: 20px 0;
      font-size: 16px;
      text-align: center;
      color: $color-rouge;

      font-family: "Roboto-Medium";
      .general-button {
        margin: 10px auto;
      }
    }
  }

  .action-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .main-img {
      width: 100%;
      height: 100%;
      background-image: url("../../images/main_img.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

@media screen and (max-width: 767px) {
  #home_page {
    flex-direction: column;
    .main-content {
      padding: 0;

      .secondary-header {
        font-size: 30px;
        margin: 20px 0;
      }
    }

    .action-content {
      .main-img {
        height: 350px;
      }
    }
  }
}
