@import "../../styles/main.scss";

#writing_page {
  padding: 20px 0;

  .working-area {
    display: flex;
    gap: 10px;
    height: 500px;
    .task-container {
      width: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .task {
      font-family: "Roboto-Medium";
      margin: 10px 0;
    }

    .diagram {
      margin-top: 10px;
    }
  }

  .task-navigation {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 10px;
  }
}

@media screen and (max-width: 767px) {
  #writing_page {
    .working-area {
      flex-direction: column;
      height: 100%;
    }
  }
}
