@import "../../../styles/main.scss";

#essay_types {
  .type-container {
    margin: 30px 0;
  }
  ul {
    margin-left: 30px;
    li {
      margin-bottom: 10px;
    }
  }
}
