@import "../../styles/main.scss";

#articles_list {
  margin: 40px 0;
  font-size: 16px;

  h2 {
    margin-bottom: 15px;
    color: $color-prussian-blue;
    font-family: "Roboto-Bold";
  }

  .topic {
    margin-bottom: 10px;
    .topic-title {
      &:hover {
        color: $color-rouge;
        transform: scale(1.02);
        transition: transform 0.5s ease-out;
      }
    }
  }
}
