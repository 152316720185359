@import "./main.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  margin: 0 auto;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 14px;
  font-family:
    "Roboto-Regular",
    -apple-system,
    BlinkMacSystemFont,
    segoe ui,
    Roboto,
    helvetica neue,
    Arial,
    sans-serif,
    apple color emoji,
    segoe ui emoji,
    segoe ui symbol,
    noto color emoji;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: none;
}

a {
  text-decoration: none;
  color: $color-black;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

button,
a {
  cursor: pointer;
}

button {
  font-size: 14px;
}

#root {
  background-color: $color-white;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    padding: 0 60px;
    margin: 0 auto;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .article-template {
    max-width: 800px;
    margin: 0 auto;
    .general-button {
      margin: 10px auto;
    }
  }
}

h1 {
  font-size: 40px;
  color: $color-prussian-blue;
  font-family: "Roboto-Bold";
  line-height: normal;
}

.gradient-divider {
  width: 100%;
  height: 10px;
  background-color: $color-prussian-blue; // for browsers that do not support gradient
  background-image: linear-gradient(to right, $color-prussian-blue, $color-rouge);
}

#header_container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

#logo {
  width: 125px;
  height: 52px;
}

.general-modal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  padding-top: 110px;
}

.basic-modal {
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  min-height: 200px;
  width: 50%;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.article {
  margin: 40px auto;
  max-width: 800px;
  font-size: 16px;
  text-align: justify;

  h1 {
    text-align: left;
  }

  &-body {
    margin: 40px 0;

    div {
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 20px;
      }

      .bullet-point {
        margin: 3px 5px 0 0;
        width: 20px;
        height: 20px;
        float: left;
      }
    }

    .example {
      margin: 30px auto;
    }
  }
}

.link {
  margin: 0 5px;
  font-family: "Roboto-Bold";
  color: $color-rouge;

  &:hover {
    color: $color-white;
    background-color: $color-prussian-blue;
    padding: 2px 5px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 855px) {
  .layout {
    main {
      padding: 0 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }

  #header_container {
    gap: 5px;
  }

  #logo {
    width: 100px;
    height: 40px;
  }

  .general-modal {
    padding-top: 50px;
  }

  .basic-modal {
    width: 90%;
  }

  .article {
    margin: 20px auto;

    &-body {
      margin: 20px 0 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  #logo {
    width: 98px;
    height: 37px;
  }
}
