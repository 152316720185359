@import "../../styles/main.scss";

#info_page {
  padding: 40px 0;

  .info-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .main-text {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 40px auto 20px;
    font-size: 16px;
    text-align: justify;
  }

  .info-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .block-divider {
      width: 100%;
      height: 100px;
      background-color: $color-rouge;
      &:first-child {
        margin-left: -60px;
      }
      &:last-child {
        margin-right: -60px;
      }
    }
    .thinking-people {
      width: 60%;
    }

    @-moz-document url-prefix() {
      .thinking-people {
        width: 250%;
      }
    }
  }

  .info-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    margin: 40px auto;
    font-size: 16px;
    text-align: justify;
  }
}

@media screen and (max-width: 767px) {
  #info_page {
    padding: 20px 0;

    .main-text {
      margin-top: 20px;
    }

    .info-img {
      .block-divider {
        &:first-child {
          margin-left: -20px;
        }
        &:last-child {
          margin-right: -20px;
        }
      }
      .thinking-people {
        width: 85%;
      }

      @-moz-document url-prefix() {
        .thinking-people {
          width: 500%;
        }
      }
    }
  }
}
