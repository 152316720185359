@import "./../../styles//main.scss";

#about_us {
  padding: 20px 0;
  .about-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 20px 0 30px;
    }

    .header-divider {
      width: 5px;
      height: 80px;
      background-color: $color-prussian-blue; // for browsers that do not support gradient
      background-image: linear-gradient($color-prussian-blue, $color-rouge);
    }

    .header-text {
      font-family: "Roboto-Medium";
      font-size: 20px;
      text-align: right;
      color: $color-lily;
    }
  }

  .main-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .block-divider {
      width: 100%;
      height: 100px;
      background-color: $color-rouge;
      &:first-child {
        margin-left: -60px;
      }
      &:last-child {
        margin-right: -60px;
      }
    }
    .people-img {
      width: 60%;
    }

    @-moz-document url-prefix() {
      .people-img {
        width: 250%;
      }
    }
  }

  .main-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    margin: 20px auto 40px;
    font-size: 16px;
    text-align: justify;
  }
}

@media screen and (max-width: 767px) {
  #about_us {
    .about-header {
      margin-bottom: 20px;

      h1 {
        margin: 0;
      }

      .header-text {
        font-size: 18px;
      }
    }

    .main-img {
      .block-divider {
        &:first-child {
          margin-left: -20px;
        }
        &:last-child {
          margin-right: -20px;
        }
      }
      .people-img {
        width: 85%;
      }

      @-moz-document url-prefix() {
        .people-img {
          width: 500%;
        }
      }
    }
  }
}
