@import "../../styles/main.scss";

.writing-container {
  width: 100%;
  height: 100%;

  textarea {
    width: 100%;
    outline: none;
    padding: 5px;
  }
}
