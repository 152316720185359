@import "../../styles/main.scss";

#timer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 5px;

  .minutes {
    font-family: "Roboto-Medium";
  }
}
