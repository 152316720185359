@import "../../styles/main.scss";

#footer {
  display: flex;
  gap: 10px;
  margin-top: auto;
  background-color: $color-prussian-blue;
  padding: 10px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  .footer-text {
    color: $color-white;
  }

  .external-link {
    color: $color-fair-pink;
    font-size: 10px;

    a {
      color: $color-rouge;
      &:hover {
        color: $color-fair-pink;
      }
    }
  }
}
