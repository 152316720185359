@import "../../styles/main.scss";

#notification {
  position: fixed;
  left: 50%;
  bottom: 50%;
  background: $color-fair-pink;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  height: fit-content;
  z-index: 10;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  user-select: none;
  transform: translate(-50%, 2000%);

  &.success {
    // color: $color;
  }

  &.error {
    #title {
      color: $color-rouge;
    }
  }

  &.warning {
    // color: $color;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    font-size: 16px;
    #title {
      font-weight: 900;
      font-family: "Roboto-Bold";
    }
  }

  &.open-animation {
    transform: translate(-50%, -50%);
    transition: ease-in 0.5s;
  }
  &.close-animation {
    transform: translate(-50%, 2000%);
    transition: ease-out 0.5s;
  }
}
