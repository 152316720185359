@import "../../styles/main.scss";

#results_page {
  margin: 40px 0 20px;

  .main-content {
    margin: 0 auto;
    max-width: 800px;

    h1 {
      margin-bottom: 20px;
    }

    .secondary-text {
      margin-bottom: 30px;
      font-family: "Roboto-Medium";
      font-size: 20px;
      color: $color-lily;
    }

    .results-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 25px;

      .logo {
        display: none;
      }

      h2 {
        color: $color-rouge;
      }

      .letter-results,
      .essay-results {
        margin-bottom: 20px;
        .task {
          font-weight: 900;
          font-family: "Roboto-Medium";
          p {
            margin-bottom: 5px;
          }
        }

        .word-count {
          font-weight: 900;
          font-family: "Roboto-Medium";
        }
      }
    }

    .general-button {
      margin: 0 auto;
    }
  }

  .action-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .general-button {
    min-width: 150px;
  }
}

@media screen and (max-width: 767px) {
  #results_page {
    margin-top: 20px;

    .main-content {
      .secondary-text {
        font-size: 18px;
      }

      .results-content {
        gap: 15px;

        h2 {
          font-size: 20px;
        }
      }
    }
  }
}
