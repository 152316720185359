@import "../../styles/main.scss";

.general-button {
  display: flex;
  justify-content: center;
  background-color: $color-fair-pink;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 2px 4px 0 rgba(116, 129, 143, 0.17);
  outline: none;
  font-family: "Roboto-Medium";
  color: $color-prussian-blue;
  &:hover {
    background-color: $color-prussian-blue;
    color: $color-white;
  }
}

.in-action {
  background-color: $color-rouge;
  color: $color-white;
}

.primary {
  background-color: $color-prussian-blue;
  color: $color-white;
  &:hover {
    background-color: $color-fair-pink;
    color: $color-prussian-blue;
  }
}
